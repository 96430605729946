import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LockIcon from '@mui/icons-material/Lock';
import { GetServerSideProps } from 'next';

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  if (ctx.req?.headers.cookie?.includes('_hive_session')) {
    return {
      redirect: {
        destination: '/art',
        permanent: false,
      },
    };
  }
  return {
    props: {},
  };
};
const SignIn = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start', // Change from 'center' to 'flex-start'
        minHeight: '100vh',
        minWidth: '100vw',
        backgroundColor: '#f5f5f5',
        pt: '30vh', // Add some top padding (in this case, 10% of the viewport height)
      }}
    >
      <Card
        sx={{
          minWidth: 400, // Adjust the width as needed
          padding: 4, // Add padding around the card content
        }}
      >
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Sign In
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Please sign in to continue
          </Typography>
        </CardContent>
        <CardActions>
          <form
            style={{ width: '100%' }}
            action="/users/auth/discord"
            method="post"
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<LockIcon />}
              size="large"
              fullWidth
              type="submit"
            >
              Sign In
            </Button>
          </form>
        </CardActions>
      </Card>
    </Box>
  );
};

export default SignIn;
